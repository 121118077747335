/* eslint-disable */
import instance from "@/services/instance.js";

const GetAllOpenTenders = payload => {
    return instance.instanceToken
      .get("openTendersSubscription/openTendersSubscription/all", payload)
      .then(function(response) {
        return response;
      })
      .catch(function() {});
  };

  const GetAllCallForTenders = payload => {
    return instance.instanceToken
      .get("openTendersSubscription/openTenders/all", payload)
      .then(function(response) {
        return response;
      })
      .catch(function() {});
  };

  const GetTenderUser = (payload) => {
    return instance.instanceToken
    .get(`/openTendersSubscription/openTenders/get/${payload}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
  };

  const UpdateCallForTenderById = (payload) => {
    return instance.instanceToken
    .post(`/openTendersSubscription/openTenders/update`, payload)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
  };

  const ImageUpload = payload => {
    return instance.instanceToken
      .post("/image", payload)
      .then(function(response) {
        return response;
      })
      .catch(function() {});
  };

  const UploadFile = payload => {
    return instance.instanceFileUpload
      .post("/partnerPromotions/upload", payload)
      .then(function(response) {
        return response;
      })
      .catch(function() {});
  };

  const ApproveOpenTender = payload => {
    return instance.instanceToken
      .post(`/openTendersSubscription/openTendersSubscription/update`, payload)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error;
      });
  };

  const GetallCTRates = (payload) => {
    return instance.instanceToken
    .get(`/openTendersSubscription/allCTRates/${payload}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      return error;
    });
  };

  const GetAllCityV4 = () => {
    return instance.instanceToken
      .get(`/city/all/v4`)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error;
      });
  };
export default {
  getAllOpenTenders: GetAllOpenTenders,
  approveOpenTender: ApproveOpenTender,
    getTenderUser : GetTenderUser,
    updateCallForTenderById : UpdateCallForTenderById,
    imageUpload: ImageUpload,
    uploadFile:UploadFile,
    getAllCallForTenders:GetAllCallForTenders,
    getallCTRates:GetallCTRates,
    getAllCityV4: GetAllCityV4,

};
